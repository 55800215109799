import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import App from "../App";
import { connect } from "react-redux";
import Home from "../views/home/home";
import Login from "../views/login/login";
import RedirectInt from "../views/redirect/redirect";
//Components

const AppRoutes = (state) => (
  <App>
    <BrowserRouter>
      <Route
        render={(location) => (
          <Switch>
            <Route exact path="/:id" component={RedirectInt} />
            {state.status !== "logged" && <Route exact path="/" component={Login} />}
            {state.status === "logged" && (
              <>
                <Route exact path="/" component={Home} />
              </>
            )}{" "}
          </Switch>
        )}
      />
    </BrowserRouter>
  </App>
);

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps)(AppRoutes);
