import "firebase/firestore";
import * as firebaseSource from "firebase";
import "firebase/firestore";
import store from "../../utils/store";
import utils from "../../utils/utils";
import { v4 as uuidv4 } from "uuid";

class Firebase {
  async saveQr(id, url, qr) {
    try {
      let data = null;
      const db = firebaseSource.firestore();
      const userRef = await db
        .collection("qr")
        .add({
          id: id,
          url: url,
          qr: qr,
          status: "PENDIENTE",
          created: new Date(),
        })
        .then(function (querySnapshot) {
          utils.sendNotification(
            "¡Código Creado!",
            "Recuerda activar el código en la sección listado de qr",
            "success"
          );
          return true;
        })
        .catch(function (error) {
          utils.sendNotification(
            "¡Ha ocurrido un error!",
            "No hemos podido crear el codigo",
            "danger"
          );
          return false;
        });
      return true;
    } catch (error) {
      utils.sendNotification(
        "¡Ha ocurrido un error!",
        "Revisa la información requerida",
        "danger"
      );
      return false;
    }
  }

  async getQr() {
    let data = [];
    const db = firebaseSource.firestore();
    const userRef = await db
      .collection("qr")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data.push({ ...doc.data(), doc_id: doc.id });
        });
      });
    return data;
  }

  async getQrById(id) {
    const db = firebaseSource.firestore();
    let list = [];
    let data = null;
    const userRef = await db
      .collection("qr")
      .where("id", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          list.push({
            ...doc.data(),
            doc: doc.id,
          });
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    if (list.length > 0) {
      data = list[0];
      await this.addStats(data);
    }
    return data;
  }

  async updateQrStatus(id, status, doc) {
    try {
      let data = [];
      const db = firebaseSource.firestore();
      const userRef = await db;
      console.log("doc" + doc);
      await db.collection("qr").doc(doc).update({
        status: status,
      });
      utils.sendNotification(
        "¡QR Actualizado!",
        "Has cambiado el estado de tu código QR a " + status,
        "success"
      );
      return data;
    } catch (error) {
      console.log("errro");
      console.log(error);
      utils.sendNotification(
        "¡Error al Actualizar!",
        "No hemos podido cambiar el estado de tu QR ponte en contacto con el equipo de soporte",
        "success"
      );
    }
  }

  async updateQrRedirect(new_url, doc) {
    try {
      let data = [];
      const db = firebaseSource.firestore();
      const userRef = await db;
      console.log("doc" + doc);
      await db.collection("qr").doc(doc).update({
        url: new_url,
      });
      utils.sendNotification(
        "¡QR Actualizado!",
        "Has cambiado el link de tu código QR a " + new_url,
        "success"
      );
      return data;
    } catch (error) {
      console.log("errro");
      console.log(error);
      utils.sendNotification(
        "¡Error al Actualizar!",
        "No hemos podido cambiar el link de tu QR ponte en contacto con el equipo de soporte",
        "success"
      );
    }
  }

  async addStats(item) {
    const doc = item.doc;
    const click = item.click && item.click > 0 ? item.click : 0;
    const times = item.times && item.times.length > 0 ? item.times : [];
    times.push(new Date());
    const db = firebaseSource.firestore();
    const userRef = db;
    await db
      .collection("qr")
      .doc(item.doc)
      .update({
        click: click + 1,
        times: times,
      });
  }

  async login(email, password) {
    let status = null;
    const login = await firebaseSource
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        if (error != null && error.code != null) {
          status = {
            login: false,
            error: error.code,
          };
        }
      });
    if (status != null) {
      return status;
    }
    const info = await this.findUserInfo(email);
    return true;
  }

  async findUserInfo(email) {
    const db = firebaseSource.firestore();
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          store.dispatch({
            type: "LOGIN",
            data: doc.data(),
          });
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }
}

const firebase = new Firebase();

export default firebase;
