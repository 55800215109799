import React, { useState } from "react";

import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import { FcOrgUnit, FcStatistics, FcTodoList } from "react-icons/fc";
import "./home.scss";
import { connect } from "react-redux";
import Loading from "../../components/loading/loading";
import firebase from "../../modules/firebase/firebase";
import QrGenerator from "../qr-generator/qr-generator";
import Handler from "../handler/handler";
import Stats from "../stats/stats";

function Home(state) {
  const [view, setView] = useState("menu");
  const [redirect, setRedirect] = useState(false);

  const changeView = (selectedView) => {
    setView(selectedView);
  };

  const logout = () => {
    setRedirect(true);
    firebase.logout();
  };

  if (redirect) {
    return <Loading />;
  }

  return (
    <>
      <Col lg="10" md="8" id="generatorCanvas">
        <Card className="bg-secondary shadow border-0">
          {view !== "menu" && (
            <a className="button-back" onClick={() => changeView("menu")}>
              Volver
            </a>
          )}
          {view === "menu" && (
            <CardBody className="px-lg-5 py-lg-5">
              <Col md="12" className="center-title">
                <h1> ¡Bienvenido {state && state.user && state.user.first_name ? state.user.first_name : "" } Selecciona que deseas hacer...</h1>
              </Col>
              <Col lg="6" md="12" className="box-padding" onClick={() => changeView("qr-generator")}>
                <Col className="button-card">
                  <FcOrgUnit className="icon-button-card" />
                  <h2>Generar QR</h2>
                </Col>
              </Col>
              <Col lg="6" md="12" className="box-padding" onClick={() => changeView("qr-list")}>
                <Col className="button-card">
                  <FcTodoList className="icon-button-card" />
                  <h2>Listado de QR</h2>
                </Col>
              </Col>
              <Col md="12" className="box-padding" onClick={() => changeView("qr-stats")}>
                <Col lg="12" md="12" className="box-padding">
                  <Col className="button-card">
                    <FcStatistics className="icon-button-card" />
                    <h2>Estadisticas QR</h2>
                  </Col>
                </Col>
              </Col>
            </CardBody>
          )}
          {view === "qr-generator" && <QrGenerator />}
          {view === "qr-list" && <Handler />}
          {view === "qr-stats" && <Stats />}
        </Card>
        <a className="button-log-out" onClick={() => logout()}>
          Salir
        </a>
      </Col>
      <img src="https://severusit.com/wp-content/uploads/2020/07/cropped-Severus-LOGO.png" className="brand-logo" />
    </>
  );
}

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps)(Home);
