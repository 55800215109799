import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col, Table, Progress } from "reactstrap";
import { FcOrgUnit, FcStatistics, FcTodoList, FcDownload } from "react-icons/fc";
import firebase from "../../modules/firebase/firebase";

import "./stats.scss";
function Stats(state) {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const [monthsData, setMonthsData] = useState([]);
  const [bestMonth, setBestMonth] = useState({
    month: 0,
    clicks: 0,
  });

  useEffect(() => {
    async function getData() {
      const data = await firebase.getQr();
      console.log(JSON.stringify(data));
      setData(data);
      setSelected(data[0]);
      calculateMonth(data[0]);
    }
    getData();
  }, []);

  const setApp = () => {
    const index = document.getElementById("selectStat").value;
    setSelected(data[index]);
    calculateMonth(data[index]);
  };

  const calculateMonth = (data) => {
    const months = [];
    let hours = [];
    const clicks = data && data.times ? data.times : [];
    let moreClicks = {};
    let clicksAux = 0;
    let clicksHourAux = 0;

    for (let index = 0; index < clicks.length; index++) {
      const element = new Date(new Date(clicks[index] * 1000));
      const month = element.getMonth();
      const hour = element.getHours();
      months[month] = months[month] != null && months[month] != undefined ? months[month] + 1 : 1;
      hours[hour] = hours[hour] != null && hours[hour] != undefined ? hours[hour] + 1 : 1;
    }
    setMonthsData(months);
    for (let index = 0; index < months.length; index++) {
      if (months[index] > clicksAux) {
        clicksAux = months[index];
        moreClicks = {
          month: index,
          clicks: months[index],
        };
      }
    }
    for (let index = 0; index < hours.length; index++) {
      if (hours[index] > clicksHourAux) {
        clicksHourAux = hours[index];
        moreClicks = {
          ...moreClicks,
          hour: index,
          clicksHours: hours[index],
        };
      }
    }
    moreClicks = {
      ...moreClicks,
      current: months[new Date().getMonth()],
    };

    setBestMonth(moreClicks);
  };

  return (
    <Card className="bg-secondary shadow border-0 qr-list table-box">
      <Col md="12" className="center-title">
        <h1>Estadisticas de QR</h1>
        {data.length == 0 && (
          <Col className="circle-loader">
            <Col className="draw"></Col>
          </Col>
        )}
        {data.length > 0 && (
          <Input type="select" name="selectStat" id="selectStat" value={data.id} onChange={() => setApp()}>
            {data.map((item, index) => (
              <option value={index}>{item.id}</option>
            ))}
          </Input>
        )}

        {selected != null && selected != {} && (
          <>
            <h4 className="chart-general">General</h4>

            <div className="click-container">
              <Col md="6" className="click-counter">
                <Col md="12" className="click-counter-info-1">
                  <span className="simple">Total</span>
                  <span className="simple">{selected.click != null ? selected.click : 0} Entradas</span>
                </Col>
              </Col>
              <Col md="6" className="click-counter">
                <Col md="12" className="click-counter-info-2">
                  <span className="simple"> Mes actual</span>
                  <span>{bestMonth.current} Entradas</span>
                </Col>
              </Col>
              <Col md="6" className="click-counter">
                <Col md="12" className="click-counter-info-3">
                  <span>Mejor Mes</span>
                  <span className="middle-data">{monthNames[bestMonth.month]}</span>
                  <span> {bestMonth.clicks} Entradas</span>
                </Col>
              </Col>
              <Col md="6" className="click-counter">
                <Col md="12" className="click-counter-info-4">
                  <span>Frecuencia</span>
                  <span className="middle-data">{bestMonth.hour + ":00 Hs -" + (bestMonth.hour + 1) + ":00 Hs"}</span>
                  <span> {bestMonth.clicksHours} Entradas Aprox.</span>
                </Col>
              </Col>
              <ul class="chart">
                <h4 className="chart-title">Entradas por mes</h4>
                {monthNames.map((item, index) => (
                  <Col md="3" className="chart-month">
                    <Col md="12" className="chart-month-data">
                      <h4>{item}</h4>
                      <h4>{monthsData[index] != null && monthsData[index] != undefined ? monthsData[index] : 0}</h4>
                    </Col>
                  </Col>
                ))}
              </ul>
            </div>
          </>
        )}
      </Col>
    </Card>
  );
}

export default Stats;
