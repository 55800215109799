import React, { useEffect, useState, Redirect } from "react";

import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import { FcInspection, FcSafe, FcCustomerSupport } from "react-icons/fc";
import { useFirebaseApp } from "reactfire";
import "./login.scss";
import { connect } from "react-redux";
import firebase from "../../modules/firebase/firebase";
import Loading from '../../components/loading/loading';

function Login(state) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [buttonClass, setButtonClass] = useState("");
  const [redirect, setRedirect] = useState(false);


  const loginMethod = async () => {
    setButtonClass("disabled");
    setError("");
    if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      if (email !== "" && password !== "") {
        const login = await firebase.login(email, password);
        if (login != null) {
          if (login.error === "auth/wrong-password") {
            setError("La contraseña y/o email que has ingresado es incorrecto");
          }
          else if (login.error === "auth/user-not-found") {
            setError("No encontramos un usuario con el email ingresado");
          }
          else if (login.error === "auth/too-many-requests") {
            setError("Ha ocurrido un error al intentar verificar tu cuenta");
          }
          else{
          setRedirect(true);
          }
        }
      } else {
        setError("Debes completar todos los campos para poder seguir");
      }
    } else {
      setError("El email ingresado no es válido");
    }
    setButtonClass("");
  };

  const updateEmail = (field) => {
    setEmail(document.getElementById("email").value);
  };

  const updatePassword = (field) => {
    setPassword(document.getElementById("password").value);
  };

if (redirect) {
    return <Loading />
}
  //code: "auth/invalid-email"
  return (
    <>
      <Col lg="6" md="8" id="login">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5 card-header">
            <div className="d-block d-sm-none">
            </div>
            <div className="d-none d-sm-block">
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h1>¡Bienvenido al panel de QR!</h1>
              <h2>Plataforma para la gestion de QR.</h2>
              <p className="error">{error}</p>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Ingresa tu email" type="email" id="email" name="email" value={email !== "" ? email : ""} onChange={() => updateEmail()} required />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Contraseña" type="password" id="password" name="password" value={password !== "" ? password : ""} onChange={() => updatePassword()} required />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className={"mt-4 " + buttonClass} color="primary" type="button" onClick={() => loginMethod()}>
                  {buttonClass !== "disabled" && <span>Iniciar Sesión</span>}
                  {buttonClass === "disabled" && <div className="loadingspinner" />}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

const mapStateToProps = state => ({
  status: state.status,
});

export default connect(mapStateToProps)(Login);
