import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap";
import {
  FcOrgUnit,
  FcStatistics,
  FcTodoList,
  FcCancel,
  FcSupport,
  FcCheckmark,
  FcDownload,
} from "react-icons/fc";
import firebase from "../../modules/firebase/firebase";

import "./handler.scss";
function Handler(state) {
  const [data, setData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [editing, setEditing] = useState({});

  useEffect(() => {
    async function getData() {
      const data = await firebase.getQr();
      setSearchResult(data);
      setData(data);
    }
    getData();
  }, []);

  useEffect(() => {}, [searchResult]);

  const changeStatus = (index, id, doc) => {
    const status = document.getElementById("status" + index).value;

    const dataList = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          status: status,
        };
      }
      return item;
    });
    setSearchResult(dataList);
    setData(dataList);
    firebase.updateQrStatus(id, status, doc);
  };

  const searchList = (search) => {
    const filtered = data.filter((item) => item.id.includes(search));
    setSearchResult([...filtered]);
  };

  const handleEditing = (id, isEditing, text) => {
    setEditing({
      ...editing,
      [id]: {
        change: isEditing,
        value: text,
      },
    });
  };

  const updateField = (text, id) => {
    setEditing({
      ...editing,
      [id]: {
        change: true,
        value: text,
      },
    });
  };

  const cleanField = (id) => {
    setEditing({
      ...editing,
      [id]: null,
    });
  };

  const saveField = async (id, doc_id) => {
    setEditing({
      ...editing,
      [id]: {
        ...editing[id],
        saving: true,
      },
    });
    const dataList = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          url: editing[id].value,
        };
      }
      return item;
    });
    setSearchResult(dataList);
    setData(dataList);
    await firebase.updateQrRedirect(editing[id].value, doc_id);
    cleanField(id);
  };

  return (
    <Card className="bg-secondary shadow border-0 qr-list table-box">
      <Col md="12" className="center-title">
        <h1>Listado de QR</h1>
        <input
          type="text"
          onChange={(e) => searchList(e.target.value)}
          placeholder="Buscar por id"
          className="search-box"
        />
        {searchResult.length == 0 ? (
          <div className="circle-loader">
            <div className="draw"></div>
          </div>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Redirect url</th>
                <th>Status</th>
                <th>Descargar</th>
              </tr>
            </thead>
            <tbody className="table-result">
              {searchResult.map((item, index) => (
                <tr key={index}>
                  <td className="table-result-id">{item.id}</td>
                  <td className="table-result-url">
                    {editing[item.id] ? (
                      <>
                        <input
                          type="text"
                          id={`field-update${item.id}`}
                          value={editing[item.id].value}
                          onChange={(e) => updateField(e.target.value, item.id)}
                          className="table-result-input"
                        />
                        {editing[item.id].saving ? (
                          <div className="circle-loader">
                            <div className="draw"></div>
                          </div>
                        ) : (
                          <>
                            <FcCancel
                              className="table-result-cancel"
                              onClick={() => cleanField(item.id)}
                            />{" "}
                            <FcCheckmark
                              className="table-result-checkmark"
                              onClick={() => saveField(item.id, item.doc_id)}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {item.url}{" "}
                        <FcSupport
                          className="table-result-edit"
                          onClick={() => handleEditing(item.id, true, item.url)}
                        />
                      </>
                    )}
                  </td>
                  <td className="table-result-status">
                    <Input
                      type="select"
                      name="select"
                      id={"status" + index}
                      value={item.status}
                      onChange={() => changeStatus(index, item.id, item.doc_id)}
                      className={item.status + " " + "status"}
                    >
                      <option key={"PENDIENTE"}>PENDIENTE</option>
                      <option key={"ACTIVO"}>ACTIVO</option>
                      <option key={"DESACTIVADO"}>DESACTIVADO</option>
                    </Input>
                  </td>
                  <td className="table-result-qr">
                    <a
                      href={item.qr}
                      download
                      className="btn col-12 confirm-card-button loading-btn--pending"
                    >
                      <FcDownload />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Card>
  );
}

export default Handler;
