import React, { useState } from "react";

import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col, Label, CustomInput } from "reactstrap";
import { FcOrgUnit, FcStatistics, FcTodoList, FcDownload } from "react-icons/fc";
import "./qr-generator.scss";
import { connect } from "react-redux";
import Loading from "../../components/loading/loading";
import firebase from "../../modules/firebase/firebase";
import InputColor from "react-input-color";
import Qr from "../qr/qr";

function QrGenerator(state) {
  const svgToDataURL = require("svg-to-dataurl");
  const [view, setView] = useState("menu");
  const [redirect, setRedirect] = useState(false);
  const [qrUrl, setQrUrl] = useState("");
  const [button, setButton] = useState("none");
  const [qrData, setQrData] = useState({
    backgroundColor: "#fff",
    qrColor: "black",
    margin: true,
    width: 50,
    height: 50,
    errorLevel: "L",
    url: "http://severusit.com/",
    includeImage: false,
    image: null,
  });

  const changeView = (selectedView) => {
    setView(selectedView);
  };

  const changeProperty = (field) => {
    if (field != null && field != undefined && field != "") {
      let dataField = document.getElementById(field).value;

      if (field == "includeImage") {
        dataField = document.getElementById(field).checked;
        if (dataField) {
          setQrData({
            ...qrData,
            [field]: dataField,
            image: {
              center: true,
            },
          });
        } else {
          setQrData({
            ...qrData,
            [field]: dataField,
            image: null,
          });
        }
      } else {
        if (field == "margin") {
          dataField = document.getElementById(field).checked;
        }
        setQrData({
          ...qrData,
          [field]: dataField,
        });
      }
    }
  };

  const changePropertyImage = (field) => {
    let dataField = document.getElementById(field).value;
    if (field == "center") {
      dataField = document.getElementById(field).checked;
      let x = null;
      let y = null;
      if (!dataField) {
        if (qrData.margin) {
          x = 80;
          y = 80;
        } else {
          x = 110;
          y = 110;
        }
      }
      setQrData({
        ...qrData,
        image: {
          ...qrData.image,
          [field]: dataField,
          x: x,
          y: y,
        },
      });
    } else {
      if (field == "excavate") {
        dataField = document.getElementById(field).checked;
      }
      setQrData({
        ...qrData,
        image: {
          ...qrData.image,
          [field]: dataField,

        },
      });
    }
  };

  const downloadQR = async () => {
    setButton("pending");
    const svg = document.getElementById("qrGenerated");
    const serializedSVG = new XMLSerializer().serializeToString(svg);
    const base64Data = window.btoa(serializedSVG);
    const dataUrl = svgToDataURL(base64Data);
    const result = "data:image/svg+xml;base64," + base64Data;
    setQrUrl(result);
    const response = await firebase.saveQr(qrData.id, qrData.url, result);
    console.log(response);
    if (response) {
      setButton("complete");
    } else {
      setButton("error");
    }
  };

  if (redirect) {
    return <Loading />;
  }

  return (
    <>
      <Card className="bg-secondary shadow border-0">
        {view === "menu" && (
          <CardBody className="px-lg-5 py-lg-5">
            <>
              <Col md="12" className="center-title">
                <h1>Generador de código QR</h1>
              </Col>
              <Row>
                <Col md="6" className="logo-edit-area">
                  <Row>
                    <h4>Configuración</h4>
                  </Row>
                  <Col md="4" className="form-label">
                    <label>Fondo:</label>
                    <input
                      type="color"
                      value={qrData.backgroundColor}
                      onChange={() => changeProperty("backgroundColor")}
                      placement="right"
                      id="backgroundColor"
                      name="backgroundColor"
                      className="input-color"
                    />
                  </Col>
                  <Col md="4" className="form-label">
                    <label>QR:</label>
                    <input type="color" value={qrData.qrColor} onChange={() => changeProperty("qrColor")} placement="right" id="qrColor" name="qrColor" className="input-color" />
                  </Col>
                  <Col md="4" className="form-label">
                    <FormGroup>
                      <Label for="exampleCheckbox">Margen</Label>
                      <div>
                        <CustomInput type="switch" checked={qrData.margin} onChange={() => changeProperty("margin")} id="margin" name="margin" />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="12" className="form-label">
                    <FormGroup className="form-inline-input">
                      <Col md="1">
                        <label>url</label>
                      </Col>
                      <Col md="10">
                        <Input className="form-control-alternative" value={qrData.url} onChange={() => changeProperty("url")} placement="right" id="url" name="url" />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md="12" className="form-label">
                    <FormGroup className="form-inline-input">
                      <Col md="1">
                        <label>id</label>
                      </Col>
                      <Col md="10">
                        <Input className="form-control-alternative" value={qrData.id} onChange={() => changeProperty("id")} placement="right" id="id" name="id" />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md="4" className="form-label">
                    <FormGroup>
                      <Label for="exampleCheckbox">Imágen</Label>
                      <div>
                        <CustomInput type="switch" checked={qrData.includeImage} onChange={() => changeProperty("includeImage")} id="includeImage" name="includeImage" />
                      </div>
                    </FormGroup>
                  </Col>
                  {qrData.includeImage == true && (
                    <>
                      <Col md="4" className="form-label">
                        <FormGroup>
                          <Label for="exampleCheckbox">Merge</Label>
                          <div>
                            <CustomInput type="switch" checked={qrData.image.excavate} onChange={() => changePropertyImage("excavate")} id="excavate" name="excavate" />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="form-label">
                        <FormGroup>
                          <Label for="exampleCheckbox">Centrar</Label>
                          <div>
                            <CustomInput type="switch" checked={qrData.image.center} onChange={() => changePropertyImage("center")} id="center" name="center" />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12" className="form-label">
                        <FormGroup className="form-inline-input">
                          <Col md="1">
                            <label>src</label>
                          </Col>
                          <Col md="10">
                            <Input className="form-control-alternative" value={qrData.image.src} onChange={() => changePropertyImage("src")} placement="right" id="src" name="src" />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="form-label">
                        <FormGroup className="form-inline-input">
                          <Col md="4">
                            <label>Ancho</label>
                          </Col>
                          <Col md="8">
                            <Input className="form-control-alternative" value={qrData.image.height} onChange={() => changePropertyImage("height")} placement="right" id="height" name="height" />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="form-label">
                        <FormGroup className="form-inline-input">
                          <Col md="4">
                            <label>Largo</label>
                          </Col>
                          <Col md="8">
                            <Input className="form-control-alternative" value={qrData.image.width} onChange={() => changePropertyImage("width")} placement="right" id="width" name="width" />
                          </Col>
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Col>
                <Col md="6" className="logo-previsual-area">
                  <Row>
                    <h4>Previsualizar</h4>
                  </Row>
                  <Qr data={qrData} />

                  <Col className="confirm-card">
                    {button == "none" && (
                      <a onClick={() => downloadQR()} className="btn btn-primary col-12 confirm-card-button loading-btn--pending">
                        Crear código
                      </a>
                    )}
                    {button == "pending" && (
                      <div class="circle-loader">
                        <div class="draw"></div>
                      </div>
                    )}
                    {button == "complete" && (
                      <>
                        <div class="circle-loader load-complete">
                          <div class="checkmark draw"></div>
                        </div>
                        <a href={qrUrl} download className="btn col-12 confirm-card-button loading-btn--pending">
                          <FcDownload />
                        </a>
                      </>
                    )}
                    {button == "error" && (
                      <a href={qrUrl} onClick={() => downloadQR()} className="btn btn-danger col-12 confirm-card-button loading-btn--pending">
                        Crear código
                      </a>
                    )}
                  </Col>
                </Col>
              </Row>

              <Row></Row>
            </>
          </CardBody>
        )}
      </Card>
    </>
  );
}

export default QrGenerator;
