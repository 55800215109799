import React, { Component, useEffect, useState } from "react";
import "./loading.scss";

const Loading = (props) => {
  return (
    <>
      <div id="load">
        <img alt="..." src={require("../../assets/loaders/scan.svg")} className="logo-loader" />
      </div>
      <div id="load">
        <img alt="..." src={require("../../assets/loaders/scan-mask.svg")} className="logo-loader loader-mask" />
      </div>
    </>
  );
};
export default Loading;
