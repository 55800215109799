import React, { Component, Fragment, useState, useEffect,useRef } from "react";
import { Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import firebase from "../../modules/firebase/firebase";

import "./qr.scss";

const Qr = (props) => {
  let QRCode = require("qrcode.react");
  const [svgQr, setSvgQr] = useState("");
  const downloadRef = useRef("");

  useEffect(() => {
    setSvgQr(document.getElementById("qrGenerated"));
  }, []);

  return (
    <Col>
      <Col className="qr-canvas">
        <QRCode
          value={window.location.origin + "/" + props.data.id}
          bgColor={props.data.backgroundColor}
          fgColor={props.data.qrColor}
          includeMargin={props.data.margin}
          renderAs="svg"
          level={props.data.errorLevel}
          level={props.data.errorLevel}
          imageSettings={props.data.image}
          id="qrGenerated"
          name="qrGenerated"
          version="1.1" xmlns="http://www.w3.org/2000/svg"
        />
      </Col>
    </Col>
  );
};


export default Qr;
