import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import firebase from "../../modules/firebase/firebase";
import Loading from "../../components/loading/loading";

import "./redirect.scss";

const RedirectInt = (props) => {
  const [data, setData] = useState(null);
  const [response, setResponse] = useState(false);

  useEffect(() => {
    async function getData() {
      if (props && props.match.params.id) {
        const response = await firebase.getQrById(props.match.params.id);
        setData(response);
        setResponse(true);
      }
    }
    getData();
  }, []);

  if (response == false) {
    return <Loading />;
  } else {
    if (data != null &&data.status == "ACTIVO") {
      window.location = data.url + window.location.search;
    }
  }

  return <>{response == true && (data == null || data != null && data.status != "ACTIVO") && <img src={require("../../assets/not-found.svg")} className="button-not-found" />}</>;
};

export default RedirectInt;
